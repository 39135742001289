<template>
  <div>
    <button-quick-access
      :disabled="showEnterMeetingToJoin"
      :size="size"
      label="Sala de um grupo ou de outro utilizador"
      title="Entrar numa sala com Id"
      @clicked="toggleModal"
    />
    <ModalEnterMeetingToJoin :show="showEnterMeetingToJoin" @close="toggleModal" />
  </div>
</template>

<script>
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import ModalEnterMeetingToJoin from '../modals/ModalEnterMeetingToJoin'

export default {
  components: {
    ButtonQuickAccess,
    ModalEnterMeetingToJoin,
  },

  props: {
    size: {
      type: String,
      default: 'base',
    },
  },

  data() {
    return {
      showEnterMeetingToJoin: false,
    }
  },

  computed: {
    isMobile() {
      return this.window.width < 640
    },
  },

  methods: {
    toggleModal() {
      this.showEnterMeetingToJoin = !this.showEnterMeetingToJoin
    },
  },
}
</script>
