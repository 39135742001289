<template>
  <div>
    <div class="flex gap-3 text-gray-400 font-semibold text-base items-center mb-3">
      <slot name="header" />
    </div>
    <div class="grid gap-4" :class="[gridTemplateColumns]">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelMeetingButtonsGroup',
  props: {
    numberColumns: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    gridTemplateColumns() {
      return `grid-cols-${this.numberColumns}`
    },
  },
}
</script>
