var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"text-center transition-colors font-bold py-3 rounded-xl min-w-button flex items-center justify-center text-base",class:{
    'bg-button-red text-white': (_vm.color === 'red' && !_vm.loading) || (_vm.loading && _vm.loadingBackground === 'red'),
    'bg-button-gray text-white': (_vm.color === 'gray' && !_vm.loading) || (_vm.loading && _vm.loadingBackground === 'gray'),
    'bg-button-inactive text-button-inactive':
      (_vm.color === 'inactive' && !_vm.loading) || (_vm.loading && _vm.loadingBackground === 'inactive'),
    'bg-button-primary text-white': (_vm.color === 'primary' && !_vm.loading) || (_vm.loading && _vm.loadingBackground === 'primary'),
    'bg-button-inactive': (_vm.color === 'bginactive' && !_vm.loading) || (_vm.loading && _vm.loadingBackground === 'bginactive'),
    'bg-primary text-white': (_vm.color === 'active' && !_vm.loading) || (_vm.loading && _vm.loadingBackground === 'active'),
  }},[(_vm.loading)?_c('fw-icon-loading',{staticClass:"w-10 h-10 text-white"}):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }