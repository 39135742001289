<template>
  <DashboardCard
    class="p-3 pod-card rounded-3xl"
    :data-letter="userLetter"
    :no-shrink="noShrink"
    :full-width="fullWidth"
  >
    <div class="relative" :class="{ 'w-full': fullWidth }">
      <div class="flex flex-1 items-center gap-5 relative px-2" :class="{ 'w-full': fullWidth }">
        <fw-avatar
          class="flex-shrink-0 relative"
          size="md"
          custom-class=" force-rounded force-h-14 force-w-14 '"
          :show-as-online="isActive"
          :user="user"
        />
        <div class="text-left leading-5 flex-1 text-white relative text-xl">
          <v-clamp autoresize :max-lines="2" class="font-semibold">
            {{ getPersonName() }}
          </v-clamp>
        </div>

        <div class="flex gap-3 items-center mx-5">
          <div v-if="asWebinar && pod.attendee.withRole('show_to_everyone')">
            <fw-tag type="primary" size="lg">
              <div class="flex gap-2 items-center">
                <fw-icon-star class="h-5 w-5 text-white" />
                <span>Orador</span>
              </div>
            </fw-tag>
          </div>
          <div
            v-if="!withAudio && showAudio"
            class="h-8 w-8 flex flex-col items-center justify-center rounded-full bg-red-500"
          >
            <fw-icon-mic-off class="h-5 w-5 text-white"></fw-icon-mic-off>
          </div>
          <div
            v-else-if="withAudio && showAudio"
            class="h-8 w-8 flex flex-col items-center justify-center rounded-full bg-primary"
          >
            <fw-icon-mic class="h-5 w-5 text-white"></fw-icon-mic>
          </div>
          <div
            v-if="!withCamera && showCamera"
            class="h-8 w-8 flex flex-col items-center justify-center rounded-full bg-red-500"
          >
            <fw-icon-camera-off class="h-5 w-5 text-white"></fw-icon-camera-off>
          </div>
          <div
            v-else-if="withCamera && showCamera"
            class="h-8 w-8 flex flex-col items-center justify-center rounded-full bg-primary"
          >
            <fw-icon-camera-full class="h-4 w-4 text-white"></fw-icon-camera-full>
          </div>
        </div>

        <div v-if="showActionButtons" class="flex items-center gap-5 w-1/3">
          <div v-if="!audioAllowed" class="flex-1">
            <fw-button
              v-if="!audioAllowed"
              type="link-primary"
              class="bg-black bg-opacity-20"
              size="md"
              custom-class="rounded-2xl"
              expanded
              @click.native="$emit('allowAttendeeHand')"
            >
              Dar palavra
            </fw-button>
          </div>
          <div class="flex-1">
            <fw-button
              :type="audioAllowed ? 'danger' : 'link-danger'"
              :class="{ 'bg-black bg-opacity-20': !audioAllowed }"
              expanded
              custom-class="rounded-2xl"
              size="md"
              @click.native="$emit('removeAttendeeHand')"
            >
              {{ freeMicrophones ? 'Baixar a mão' : 'Tirar palavra' }}
            </fw-button>
          </div>
        </div>
        <div v-else-if="showAcceptButtons" class="flex items-center gap-5 w-1/3">
          <div class="flex-1">
            <fw-button
              type="link-primary"
              class="bg-black bg-opacity-20"
              size="md"
              custom-class="rounded-2xl"
              expanded
              @click.native="$emit('allowAttendee')"
            >
              Aceitar entrar
            </fw-button>
          </div>
          <div class="flex-1">
            <fw-button
              :type="'link-danger'"
              class="bg-black bg-opacity-20"
              expanded
              custom-class="rounded-2xl"
              size="md"
              @click.native="$emit('rejectAttendee')"
            >
              Rejeitar
            </fw-button>
          </div>
        </div>
        <div class="flex items-center gap-5">
          <fw-button
            v-if="kickable"
            :type="'link-danger'"
            class="bg-black bg-opacity-20"
            custom-class="rounded-2xl"
            size="md"
            @click.native="$emit('kickAttendee')"
          >
            Expulsar
          </fw-button>
        </div>
        <div v-if="pod && asWebinar && canPromote">
          <fw-button
            :type="'link-primary'"
            class="bg-black bg-opacity-20"
            custom-class="rounded-2xl"
            size="md"
            @click.native="$emit('toggle-promote', pod.attendee, false, false)"
            >Permissões</fw-button
          >
        </div>
      </div>
    </div>
  </DashboardCard>
</template>
<script>
import DashboardCard from '@/fw-modules/fw-core-vue/forms/components/cards/DashboardCard'
export default {
  name: 'PodCard',
  components: {
    DashboardCard
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    pod: {
      type: Object,
      required: false
    },
    withCamera: {
      type: Boolean,
      default: false
    },
    withAudio: {
      type: Boolean,
      default: false
    },
    showCamera: {
      type: Boolean,
      default: true
    },
    showAudio: {
      type: Boolean,
      default: true
    },
    showInfo: {
      type: Boolean,
      default: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    noShrink: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    showActionButtons: {
      type: Boolean,
      default: false
    },
    showAcceptButtons: {
      type: Boolean,
      default: false
    },
    audioAllowed: {
      type: Boolean,
      default: false
    },
    freeMicrophones: {
      type: Boolean,
      default: false
    },
    kickable: {
      type: Boolean,
      default: false
    },
    asWebinar: {
      type: Boolean,
      default: false
    },
    canPromote: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userLetter() {
      return this.getPersonName()
        .charAt(0)
        .toUpperCase()
    }
  },
  methods: {
    getPersonName() {
      return this.user.name || this.user.full_name
    }
  }
}
</script>

<style>
.force-rounded {
  border-radius: 0.45rem !important;
}
.force-h-14 {
  height: 3.5rem !important;
}
.force-w-14 {
  width: 3.5rem !important;
}
.answer-card .secondary-text {
  color: #cccccc;
}
</style>
