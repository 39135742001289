<template>
  <div class="flex flex-1 h-full">
    <div
      class="transition-all flex flex-col justify-center gap-2 text-white font-semibold"
      :style="{
        width: podsOverflow ? '50px' : '0px',
        opacity: podsOverflow ? '1' : '0'
      }"
    >
      <div
        v-for="index in 26"
        :key="'alphabet_' + index"
        class="flex gap-2 text-alphabet items-center hover:text-white group cursor-pointer"
        :class="{
          'opacity-20 cursor-not-allowed': existingLetters.indexOf(String.fromCharCode(64 + index)) === -1
        }"
        @click="scrollToLetter(String.fromCharCode(64 + index))"
      >
        <div class="h-3 w-3 rounded-full bg-alphabet-ball group-hover:bg-white"></div>
        {{ String.fromCharCode(64 + index) }}
      </div>
    </div>
    <div v-if="pods.length > 0" class="flex-1 relative">
      <div ref="podslist" class="h-full overflow-y-scroll flex flex-col gap-3 justify-start rounded-lg hide-scrollbar">
        <PodCard
          v-for="pod in orderedPods"
          :key="pod.id"
          class="flex-shrink-0"
          full-width
          :user="pod.attendee.user"
          :pod="pod"
          :with-camera="hasVideo(pod.video)"
          :with-audio="pod.withAudio"
          :kickable="true"
          :as-webinar="asWebinar"
          :can-promote="canPromote"
          @toggle-promote="togglePromote"
          @kickAttendee="$emit('kick-participant', pod.attendee)"
        ></PodCard>
      </div>
      <div v-if="podsOverflow" class="absolute bottom-0 left-0 right-0 h-10 dashboard-overflow-gradient"></div>
    </div>
    <div v-else class="flex-1 flex flex-col justify-center items-center gap-3 opacity-20">
      <fw-icon-map-pin-user class="h-10 w-10" />
      <div class="text-white text-lg font-semibold">Sem participantes</div>
    </div>
  </div>
</template>

<script>
import PodCard from '@/components/cards/PodCard'
export default {
  name: 'PanelParticipants',
  components: {
    PodCard
  },
  props: {
    pods: {
      type: Array,
      default: () => []
    },
    attendees: {
      type: Object,
      required: true
    },
    asWebinar: {
      type: Boolean,
      default: false
    },
    canPromote: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      podsOverflow: true
    }
  },
  computed: {
    existingLetters() {
      return this.pods.map(a => a.name[0].toUpperCase())
    },
    orderedPods() {
      return [...this.pods].sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    scrollToLetter(letter) {
      var el = document.querySelector('.pod-card[data-letter="' + letter + '"]')
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    },
    hasVideo(video) {
      return typeof video !== 'undefined' && video !== null
    },
    togglePromote(attendee, noModal, promote) {
      this.$emit('toggle-promote', attendee, noModal, promote)
    }
  }
}
</script>
