<template>
  <fw-layout :header="false" full :loading="initialLoading" loading-icon="live" loading-title="UC Digital Desk">
    <template #main>
      <div class="h-full overflow-auto w-[36rem] bg-gray-100 p-10 flex flex-col gap-20">
        <div class="flex gap-5 justify-between">
          <div class="flex flex-col gap-0.5">
            <div><AppLogo /></div>
            <div class="text-xs opacity-30">{{ appVersion }}</div>
          </div>
          <div class="flex flex-col justify-end items-end -mt-1">
            <div class="text-2xl font-semibold flex items-center w-[6.5rem]">
              <div class="hour w-8 text-left">{{ clock.hours || '00' }}</div>
              <div class="text-gray-500">:</div>
              <div class="min w-8 text-left">{{ clock.minutes || '00' }}</div>
              <div class="text-gray-400">:</div>
              <div class="text-gray-400 w-9 text-left">{{ clock.seconds || '00' }}</div>
            </div>
            <div class="text-sm font-medium text-gray-400 w-28 text-left pl-2">
              {{ today | formatDate }}
            </div>
          </div>
        </div>
        <div class="flex-1 flex flex-col gap-20">
          <div class="flex flex-col gap-10 max-w-xl mx-auto">
            <div class="grid grid-cols-3 gap-2 text-sm text-gray-400 font-medium">
              <div
                class="flex flex-col items-center justify-center"
                :class="{ 'text-primary font-bold': unreadChatMessages > 0 }"
              >
                <div class="mb-1">
                  <fw-icon-message class="w-6 h-6" />
                </div>
                <div>{{ unreadChatMessages }} nova{{ unreadChatMessages === 1 ? '' : 's' }}</div>
                <div class="text-xs font-normal">Mensagens</div>
              </div>
              <BlockUCId :user="user" />
              <div
                class="flex flex-col items-center justify-center"
                :class="{ 'text-primary font-bold': unreadNotifications > 0 }"
              >
                <div class="mb-1">
                  <fw-icon-bell class="w-6 h-6" />
                </div>
                <div>{{ unreadNotifications }} nova{{ unreadNotifications === 1 ? '' : 's' }}</div>
                <div class="text-xs font-normal">Notificações</div>
              </div>
            </div>
            <div>
              <h2 class="text-3xl font-semibold text-center max-w-sm mx-auto">
                <v-clamp autoresize :max-lines="3">{{ $t('hello') }}, {{ user.name }}.</v-clamp>
              </h2>
            </div>
          </div>
          <fw-panel title="Acesso rápido">
            <div class="grid grid-cols-1 gap-5 justify-center items-stretch">
              <button-quick-access
                v-if="meeting && meeting.key"
                :label="meeting.running ? 'Sala aberta' : 'Sala fechada'"
                title="Abrir a sua sala pessoal"
                size="xl"
                :footer="meeting.key_alias"
                @clicked="$router.push({ name: 'live', params: { key: meeting.key_alias } })"
              />
              <ButtonQuickAccessEnterRoom size="xl" />
            </div>
          </fw-panel>
        </div>
        <div>
          <fw-button type="danger" size="lg" expanded @click.native="logout">Terminar sessão</fw-button>
        </div>
      </div>
      <div class="flex flex-col h-full w-full">
        <div v-if="hasClasses" class="flex h-full">
          <div v-if="activeSessions.length" class="w-[64rem] h-full flex flex-col gap-5">
            <div class="px-10 pt-10 pb-5">
              <fw-heading size="h1">Aulas de hoje</fw-heading>
            </div>
            <div class="overflow-auto flex flex-col h-full px-10 gap-10">
              <div
                v-for="session in activeSessions"
                :key="session.key"
                class="group bg-teal-300 p-5 rounded-2xl flex flex-col gap-8"
              >
                <div class="text-xl font-medium opacity-80 flex items-center gap-2">
                  <div><fw-icon-calendar class="w-5 h-5" /></div>
                  <div>{{ session.start_date | formatDayWithTime }}</div>
                </div>
                <div class="flex flex-col gap-1 opacity-95">
                  <div class="line-clamp-3 text-xl font-semibold">
                    {{ session.title }}
                  </div>
                  <div class="line-clamp-3 text-3xl font-extrabold">
                    {{ session.unit.title }}
                  </div>
                  <div v-if="session.space || session.building" class="text-sm flex gap-2 items-start opacity-70 mt-3">
                    <div><fw-icon-home class="w-5 h-5" /></div>
                    <div class="line-clamp-2">{{ spaceAndBuildingText(session) }}</div>
                  </div>
                </div>
                <div>
                  <fw-button
                    type="black"
                    size="lg"
                    expanded
                    custom-class="bg-opacity-80 hover:bg-opacity-100"
                    @click.native="$router.push({ name: 'live', params: { key: session.edition.meeting.key_alias } })"
                  >
                    Entrar na sala virtual</fw-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col h-full gap-5 border border-l">
            <div class="px-10 pt-10 pb-5">
              <fw-heading size="h1" muted>Próximas aulas</fw-heading>
            </div>
            <div class="overflow-auto h-full px-10">
              <b-tabs>
                <b-tab-item v-if="todaySessions.length" label="Hoje">
                  <PanelClassSessions :sessions="todaySessions" go-to-room />
                </b-tab-item>
                <b-tab-item v-if="thisWeekSessions.length" label="Esta semana">
                  <PanelClassSessions :sessions="thisWeekSessions" go-to-room />
                </b-tab-item>
                <b-tab-item v-if="nextWeekSessions.length" label="Próxima semana">
                  <PanelClassSessions :sessions="nextWeekSessions" go-to-room />
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </div>
        <div v-else class="w-full h-full flex items-center justify-center text-xl text-gray-400 flex-col gap-10">
          <div><fw-icon-sun class="w-32 h-32 text-gray-200" /></div>
          <div>Não existem aulas agendadas para os próximos dias.</div>
        </div>
      </div>
    </template>
  </fw-layout>
</template>

<script>
// import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import BlockUCId from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUCIdQRCode'
import AppLogo from '@/components/AppLogo'
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import ButtonQuickAccessEnterRoom from '../components/buttons/ButtonQuickAccessEnterRoom'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import AcademicUtilities from '@/fw-modules/fw-core-vue/academic/services/utilities'
import PanelClassSessions from '@/fw-modules/fw-core-vue/academic/components/panels/PanelClassSessions'
import FwHeading from '../fw-modules/fw-core-vue/ui/components/text/FwHeading.vue'

export default {
  components: {
    ButtonQuickAccess,
    ButtonQuickAccessEnterRoom,
    PanelClassSessions,
    AppLogo,
    BlockUCId
  },

  data() {
    FwHeading
    return {
      initialLoading: true,
      loading: false,
      activeTab: 'classes',
      classesLoaded: false,

      // Helper
      users: {},

      // Classes
      activeSessions: [],
      todaySessions: [],
      thisWeekSessions: [],
      nextWeekSessions: [],
      nextSessionInterval: null,

      // Clock and date
      today: new Date(),
      clock: {
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      clockInterval: null,
      appVersion: process.env.VUE_APP_VERSION,
      appName: process.env.VUE_APP_KEY
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    meeting() {
      if (this.user && this.user.meeting) return this.user.meeting
      else return null
    },
    logoutUrl() {
      // return FwEnvConfig.appUrlUCId + '/logout'
      return '/logout'
    },
    // New notifications and chat messages
    unreadNotifications() {
      return this.$store.getters.getUnreadNotificationsCount
    },
    unreadChatMessages() {
      return this.$store.getters.getUnreadChatMessagesCount
    },

    // Checks / validations
    isTeacher() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('teacher'))
    },
    isStudent() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('student'))
    },
    hasClasses() {
      return (
        this.activeSessions.length ||
        this.todaySessions.length ||
        this.thisWeekSessions.length ||
        this.nextWeekSessions.length
      )
    }
  },

  watch: {
    user() {
      console.log('user changed')
      this.start()
    }
  },

  mounted() {
    this.$store.dispatch('setUser')

    this.setClock()
    this.start()

    setTimeout(() => {
      this.initialLoading = false
    }, 1500)
  },

  beforeDestroy() {
    if (this.nextSessionInterval) clearInterval(this.nextSessionInterval)
    if (this.clockInterval) clearInterval(this.clockInterval)
  },

  methods: {
    start() {
      if (this.classesLoaded) return
      if (this.isTeacher) {
        this.getNextClassesSessions()
        this.activeTab = 'classes'
        this.classesLoaded = true
      }
    },
    async getNextClassesSessions() {
      let sessions = await ServiceAcademic.getNextTeacherSessions()
      if (sessions) {
        AcademicUtilities.buildSessionDates(
          sessions,
          this.activeSessions,
          this.todaySessions,
          this.thisWeekSessions,
          this.nextWeekSessions
        )
        this.nextSessionInterval = setInterval(this.checkSessionDates, 30 * 1000)
      }
    },
    spaceAndBuildingText(session) {
      if (session.space && session.building) {
        return session.space.title + ' ' + session.building.title
      } else if (session.space) {
        return session.space.title
      } else if (session.building) {
        return session.building.title
      } else {
        return ''
      }
    },
    checkSessionDates() {
      AcademicUtilities.checkSessionDates(
        this.activeSessions,
        this.todaySessions,
        this.thisWeekSessions,
        this.nextWeekSessions
      )
    },

    // Logout
    logout() {
      window.location.href = this.logoutUrl
    },

    // Clock
    setClock() {
      this.clockInterval = setInterval(() => {
        const date = new Date()
        this.clock.hours = date.getHours()
        this.clock.minutes = this.checkSingleDigit(date.getMinutes())
        this.clock.seconds = this.checkSingleDigit(date.getSeconds())
      }, 1000)
    },
    checkSingleDigit(digit) {
      return ('0' + digit).slice(-2)
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "hello": "Olá"
  },
  "en": {
    "hello": "Hello"
  }
}
</i18n>
