<template>
  <LayoutDefault
    :main-footer="true"
    :disable-back-to="true"
    :header-toolbar="false"
    :header-session-nav="false"
    :header-avatar="false"
    :header-apps="false"
  >
    <template #main>
      <div class="w-full h-full flex flex-col justify-center items-center">
        <fw-icon-map-pin3 class="w-16 h-16"></fw-icon-map-pin3>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import store from '@/store'

export default {
  components: {
    LayoutDefault
  },

  beforeCreate() {
    store.dispatch('setApiIgnore401', true)
  },
  mounted() {
    // Keep it on mounted event so we can send the user token on appAuthLogin http call
    // This way we avoid the creation of multiple tokens
    this.checkToken()
  },
  beforeUnmount() {
    store.dispatch('setApiIgnore401', false)
  },

  methods: {
    async checkToken() {
      if (FwEnvConfig.appsUCIdSSO.includes(process.env.VUE_APP_KEY) && FwEnvConfig.appUrlUCId) {
        try {
          await ServiceAuth.appAuthLogin(this.$route.params.token)
        } catch (error) {
          console.error('Failed to check token', error)
        }
      }
      store.dispatch('setApiIgnore401', false)
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
