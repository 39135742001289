<template>
  <div class="text-xl font-bold flex items-center gap-1.5 cursor-pointer relative" @click="$emit('click')">
    <span :class="{ 'opacity-50 text-white': !active, 'text-primary': active }"><slot /></span>
    <div v-if="badge" class="leading-5 text-primary absolute -top-0.5 -right-9 w-8 text-left">
      {{ badge > 99 ? '99' : badge }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonTab',
  props: {
    badge: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
