<template>
  <div class="flex flex-1 h-full">
    <div id="chat-messages" class="flex-1 overflow-y-auto h-full relative">
      <!-- No messages yet... -->
      <div class="chat-channel-messages max-w-prose mx-auto h-auto">
        <DynamicScroller
          :items="chatActive.messages"
          :min-item-size="30"
          :buffer="50"
          key-field="key"
          class="scroller"
          @visible="chatMessagesReady"
        >
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem :item="item" :active="active" :data-index="index">
              <ChatMessage
                :message="item"
                :chat-type="chatActive.type"
                :chat-key="chatActive.key"
                :user="getChatUser(item.user_key)"
                :can-delete-all="chatActive.isManager"
              />
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </div>
    </div>
    <!-- End messages panel -->
  </div>
</template>

<script>
import ChatUtils from '@/fw-modules/fw-core-vue/chats/services/utilities'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import ChatMessage from '@/components/ChatMessage'

export default {
  name: 'PanelMessages',
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    ChatMessage,
  },
  props: {
    chatActive: Object,
    allChatUsers: Object,
    chats: {
      type: Array,
      deep: true,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    authUser() {
      return this.$store.getters.getUser
    },
  },
  created() {
    this.$emit('load-main-chat', this.chatLoadDone)
  },
  methods: {
    chatMessagesReady() {
      ChatUtils.scrollToBottom()
    },
    getChatUser(userKey) {
      if (this.authUser.key == userKey) return this.authUser
      let user = this.allChatUsers[userKey]
      if (user) return user
      else return { name: userKey }
    },
    chatLoadDone() {
      this.loading = false
    },
  },
}
</script>
