import ViewHome from '@/views/ViewHome'
import ViewMeeting from '@/views/ViewMeeting'
import ViewRegister from '@/views/ViewRegister'
import ViewLogin from '@/fw-modules/fw-core-vue/id/views/LoginTablets'

export default [
  {
    path: '/',
    name: 'home',
    component: ViewHome,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/live/:key',
    name: 'live',
    component: ViewMeeting,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/register',
    name: 'register',
    component: ViewRegister,
  },
  {
    path: '/login',
    name: 'login',
    component: ViewLogin,
    meta: { showKeyboard: false },
  },
]
