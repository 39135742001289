<template>
  <fw-layout>
    <template #main-content>
      <div class="mx-auto max-w-xl">
        <fw-heading size="h1">Register Key for UC MeetingScreen</fw-heading>
        <div v-if="room" class="my-10 flex flex-col gap-5">
          <div>
            <fw-label marginless>Key</fw-label>
            <div class="text-xl">{{ room.key }}</div>
          </div>
          <div>
            <fw-label marginless>Token</fw-label>
            <div class="text-xl">{{ room.token }}</div>
          </div>
          <div>
            <fw-button type="light" :loading="loadingRoom" @click.native="removeRoomKey">Remove</fw-button>
          </div>
        </div>
        <div v-else class="my-10 flex flex-col gap-5">
          <fw-heading size="h2">Create a new room key</fw-heading>
          <form class="flex flex-col gap-2">
            <fw-label>Token</fw-label>
            <div><b-input v-model="newToken" /></div>
            <fw-button
              type="primary"
              :disabled="loadingRoom || !validForm"
              :loading="loadingRoom"
              @click.native="createNewRoomKey"
            >
              Create
            </fw-button>
          </form>
        </div>
      </div>
    </template>
  </fw-layout>
</template>

<script>
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import FwHeading from '../fw-modules/fw-core-vue/ui/components/text/FwHeading.vue'

export default {
  components: { FwHeading },
  data() {
    return {
      room: ServiceMeetings.getLocalRoom(),
      newToken: '',
      validForm: false,
      loadingRoom: false,
    }
  },

  watch: {
    newToken() {
      this.validForm = this.newToken.length >= 8
    },
  },

  methods: {
    async removeRoomKey() {
      if (this.loadingRoom) return
      this.loadingRoom = true

      try {
        await ServiceMeetings.deleteRoom()
        this.room = null
      } finally {
        this.loadingRoom = false
      }
    },
    async createNewRoomKey() {
      if (this.loadingRoom) return
      this.loadingRoom = true

      try {
        this.room = await ServiceMeetings.createRoom(this.newToken)
        this.newToken = ''
        this.validForm = false
      } finally {
        this.loadingRoom = false
      }
    },
  },
}
</script>
