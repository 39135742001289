<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    //parse url options
    let location = window.location.href
    let url = new URL(location)
    let debug = url.searchParams.get('debug')
    if (debug == 'true') {
      localStorage.setItem('debug', true)
    } else if (debug == 'false') {
      localStorage.removeItem('debug')
    }
  },
}
</script>
