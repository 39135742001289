<template>
  <div v-if="meeting && !isRunning" class="flex flex-col gap-5">
    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-center">
        <fw-avatar v-if="!isClassEdition" size="xl" :user="meeting.context.user" />
        <fw-avatar v-else :group="meeting?.context" size="xl" animated />
      </div>
      <div class="flex flex-col items-center justify-center">
        <fw-heading size="h1">
          {{ isClassEdition || !isMeetingOwner ? meeting.title : $t('myRoom') }}
        </fw-heading>
        <fw-heading v-if="isClassEdition && meeting?.context?.unit?.title" size="h3" muted>
          {{ meeting.context.unit.title }}
        </fw-heading>
      </div>
    </div>

    <div class="flex flex-col gap-1 items-center">
      <div class="font-mono lowercase text-3xl font-medium">{{ meeting.key_alias }}</div>
      <div v-if="isMeetingOwner">
        <a class="text-xs text-primary font-semibold" href="#" @click.prevent="$emit('personalize-meeting-key')">
          {{ $t('customize') }} ID
        </a>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center text-xl font-semibold text-gray-600">
      <div v-if="meeting.running" class="text-primary relative flex flex-col gap-1 items-center mt-2">
        <div class="text-primary">
          <span class="relative w-14 flex items-center justify-center">
            <fw-icon-live class="w-9 h-9" />
            <fw-dot color="primary" wh="w-3 h-3" class="-top-1.5 -right-2" />
          </span>
        </div>
        <div>{{ $t('roomIsOpen') }}</div>
      </div>
      <div v-else-if="meeting.unlock_in">{{ $t('closedRoom') }} ({{ $t('outsideScheduledTime') }})</div>
      <div v-else>
        {{ $t('closedRoom') }}
      </div>
    </div>

    <div
      v-if="!toggleExternalMeetingPanel && (enterRequestRejected || meeting.running || allowedToStartMeeting)"
      class="my-5 flex flex-col items-center justify-center"
    >
      <div v-if="enterRequestRejected" class="mt-5 p-5 rounded-xl shadow-xl bg-white">
        <div class="has-text-weight-bold">{{ enterRequestRejected.title }}.</div>
        <div v-if="enterRequestRejected.message" class="has-margin-top is-size-4">
          <div class="has-text-tiny has-text-muted has-margin-bottom-small">
            <faicon icon="comment" class="has-margin-right-small"></faicon> Mensagem
          </div>
          <div class="is-size-3 is-width-tiny">"{{ enterRequestRejected.message }}"</div>
          <div v-if="!enterRequestRejected.blocked" class="has-margin-top has-text-small">
            <a @click.prevent="$router.go()">Fechar mensagem</a>
          </div>
        </div>
      </div>
      <div
        v-else-if="meeting.running || allowedToStartMeeting"
        class="gap-4 px-4 py-3 rounded-full bg-gray-100 items-center justify-center inline-flex"
      >
        <fw-button
          v-if="cameraAllowed"
          :class="{
            'text-red-400': !startWithCamera,
            'text-primary': startWithCamera
          }"
          type="transparent"
          :icon="startWithCamera ? 'camera-full' : 'camera-off'"
          class="x-toggle-video"
          :aria-label="startWithCamera ? 'Desligar câmara' : 'Ligar câmara'"
          @click.native="$emit('toggle-camera-start-option')"
        ></fw-button>
        <b-button
          v-if="!meeting.running && allowedToStartMeeting"
          type="is-primary"
          size="is-large"
          :loading="loading"
          class="w-[14rem] !rounded-full"
          aria-label="Abrir a sala"
          @click="$emit('start-meeting')"
        >
          {{ $t('openRoom') }}
        </b-button>
        <b-button
          v-else-if="meeting.running && (allowedToStartMeeting || allowedToJoinMeeting)"
          type="is-primary"
          size="is-large"
          :loading="loading"
          class="w-[14rem] !rounded-full"
          @click="$emit('start-meeting')"
        >
          <span class="x-enter-room">
            {{ $t('enterRoom') }}
          </span>
        </b-button>
        <b-button
          v-else-if="!waitingToBeAccepted && !allowedToStartMeeting && !allowedToJoinMeeting"
          type="is-primary"
          size="is-large"
          :loading="loading"
          class="w-[14rem] !rounded-full"
          icon-left="concierge-bell"
          @click="$emit('start-meeting')"
        >
          {{ $t('askToEnter') }}
        </b-button>
        <span v-else class="animate-flicker">
          {{ $t('waitingApproval') }}
        </span>
        <fw-button
          v-if="audioAllowed"
          :class="{ 'text-red-500': !startWithAudio, 'text-primary': startWithAudio }"
          type="transparent"
          :icon="startWithAudio ? 'mic' : 'mic-off'"
          class="x-toggle-micro"
          :aria-label="startWithAudio ? 'Desligar microfone' : 'Ligar microfone'"
          @click.native="$emit('toggle-audio-start-option')"
        ></fw-button>
      </div>
    </div>

    <div
      v-if="meeting.running && loggedUser.isAnonymous && !allowedToStartMeeting && !allowedToJoinMeeting"
      class="text-sm text-gray-500"
    >
      <div>
        {{ $t('unableToEnterReload') }}
      </div>
      <div class="mt-2">
        <a class="font-bold text-sm text-primary" href="">{{ $t('reloadPage') }}</a>
      </div>
    </div>

    <div class="flex items-center justify-center">
      <fw-button size="sm" type="link-muted" @click.native="$router.push('/')">{{
        $t('backToPreviousPages')
      }}</fw-button>
    </div>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'

export default {
  props: {
    loading: Boolean,
    isRunning: Boolean,
    meeting: Object,
    isMeetingOwner: Boolean,
    isClassEdition: Boolean,
    waitingToBeAccepted: Boolean,
    enterRequestRejected: {
      type: Object,
      default: null
    },
    startWithAudio: Boolean,
    startWithCamera: Boolean
  },

  data() {
    return {
      ownerCalled: false,
      ownerCalledLoading: false,
      classSessionPresence: null,
      classSessionSubscription: null,
      externalMeetingDetails: '',
      externalAutoAccept: true,
      toggleExternalMeetingPanel: false
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    canStartExternal() {
      return false
      // Disable for all meetings after 2023-10-30
      // return Boolean(this.meeting && this.meeting.item_type === 'class_edition')
    },
    allowedToStartMeeting() {
      return this.meeting && !this.meeting.unlock_in && ServiceMeetings.withRole(this.meeting.roles, 'can_start')
    },
    allowedToJoinMeeting() {
      return Boolean(
        this.meeting &&
          !this.meeting.unlock_in &&
          !this.loggedUser.isAnonymous &&
          ServiceMeetings.withRole(this.meeting.roles, 'can_join')
      )
    },
    audioAllowed() {
      return this.meeting && ServiceMeetings.withRole(this.meeting.roles, 'audio_allowed')
    },
    cameraAllowed() {
      return this.meeting && ServiceMeetings.withRole(this.meeting.roles, 'camera_allowed')
    },
    isMobile() {
      return this.$device.isMobile() && !this.$device.isTablet()
    },
    isSt() {
      return process.env.VUE_APP_KEY == 'ucstudent'
    },
    canCallOwner() {
      return Boolean(
        this.meeting &&
          !this.meeting.running &&
          !this.ownerCalled &&
          this.meeting.item_type === 'user' &&
          this.meeting.context.can_call
      )
    },
    isValidExternalVideoApp() {
      return utils.getExternalVideoAppFromUrl(this.externalMeetingDetails)
    }
  },

  watch: {
    meeting(meet) {
      this.loadStudentSession(meet)
      this.setExternalData()
    }
  },

  beforeDestroy() {
    if (this.classSessionSubscription) {
      this.classSessionSubscription.destroy()
      this.classSessionSubscription = null
    }
  },
  mounted() {
    this.loadStudentSession()
    this.setExternalData()
  },

  methods: {
    setExternalData() {
      if (this.meeting && this.meeting.previous_external) {
        this.externalMeetingDetails = this.meeting.previous_external.description || ''
      }
    },
    startExternalMeeting() {
      const data = {
        asExternal: true,
        externalDescription: this.externalMeetingDetails,
        externalAutoAccept: this.externalAutoAccept
      }
      this.$emit('start-meeting', data)
      this.externalAutoAccept = true
    },

    async callOwnerToMeeting() {
      if (!this.canCallOwner || this.ownerCalledLoading) return
      this.ownerCalledLoading = true

      try {
        await ServiceMeetings.callOwner(this.meeting.key)
        this.ownerCalled = true
      } finally {
        this.ownerCalledLoading = false
      }
    },

    async loadStudentSession(meeting = null) {
      if (!meeting) meeting = this.meeting
      if (!this.isSt || !meeting || !meeting.context.is_student) return

      try {
        this.classSessionPresence = await ServiceAcademic.getStudentClassPresence(meeting.item_key)
        this.classSessionSubscription = ServiceAcademic.createSubscription(
          meeting.item_key,
          this.classSessionPresence,
          true
        )
      } catch (error) {
        console.error('Failed to load student presence', error)
        this.classSessionPresence = null
        this.classSessionSubscription = null
      }
    },

    async toggleSelfStudentPresence(type) {
      if (type === this.classSessionPresence.type) {
        await ServiceAcademic.deleteSelfStudentPresence(this.meeting.item_key, this.classSessionPresence.key)
        this.classSessionPresence.type = null
      } else {
        this.$buefy.dialog.confirm({
          type: 'is-primary',
          message: `<p class="is-size-4 has-margin-top">Tem a certeza que deseja marcar a sua presença nesta aula?</p>
          <p class="has-margin-top-small is-meta">
            A sua presença é marcada automaticamente caso entre na sala virtual.
          </p>`,
          indefinite: true,
          queue: false,
          cancelText: 'Cancelar',
          confirmText: 'Marcar presença',
          canCancel: true,
          onConfirm: async () => {
            await ServiceAcademic.setSelfStudentPresence(this.meeting.item_key, this.classSessionPresence.key, type)
            this.classSessionPresence.type = type
          }
        })
      }
    },

    toClipboardMeetingUrl() {
      this.$copyText(`https://${location.host}/live/${this.meeting.key_alias}`).then(() => {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Endereço copiado`,
          position: 'is-top'
        })
      })
    }
  }
}
</script>

<i18n>
  {
    "en": {
      "roomId": "Room ID",
      "endGuestSession": "End guest session",
      "closedRoom": "The meeting room is closed",
      "roomIsOpen": "Room is open",
      "personalRoom": "Personal room",
      "myRoom": "My room",
      "customize": "Customize",
      "outsideScheduledTime": "outside scheduled time",
      "openRoom": "Open room",
      "enterRoom": "Enter room",
      "askToEnter": "Ask to enter",
      "waitingApproval": "Waiting for approval to enter!",
      "reloadPage": "Reload page",
      "unableToEnterReload": "If you are unable to enter the room, please reload this page and try again.",
      "backToPreviousPages": "Back to home"
    },
    "pt": {
      "roomId": "ID da sala",
      "endGuestSession": "Terminar sessão de convidado",
      "closedRoom": "Sala fechada",
      "roomIsOpen": "Sala aberta",
      "personalRoom": "Sala pessoal",
      "myRoom": "A minha sala",
      "customize": "Personalizar",
      "outsideScheduledTime": "fora do horário previsto",
      "openRoom": "Abrir a sala",
      "enterRoom": "Entrar na sala",
      "askToEnter": "Pedir para entrar",
      "waitingApproval": "Aguarda autorização para entrar!",
      "reloadPage": "Recarregar página",
      "unableToEnterReload": "Se não conseguir entrar na sala, por favor, recarregue esta página e tente novamente.",
      "backToPreviousPages": "voltar ao início"
    }
  }
  </i18n>
