import Vue from 'vue'
import VueRouter from 'vue-router'

import BaseRoutes from './routes'

import AuthRoutes from '@/fw-modules/fw-core-vue/id/new_routes'
import FormManageRoutes from '@/fw-modules/fw-core-vue/forms/routes-manage' // New exams
import MeetingsRoutes from '@/fw-modules/fw-meetings-vue/routes'
import CoreRouter from '@/fw-modules/fw-core-vue/router'

Vue.use(VueRouter)

//remove login route from auth routes (its going to be replaced by the one in the base routes)
let authRoutes = AuthRoutes.filter(route => route.path !== '/login')

const routes = [].concat(BaseRoutes, MeetingsRoutes, authRoutes, FormManageRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE || '/',
  routes,
})

router.beforeResolve((to, from, next) => {
  return CoreRouter.beforeResolve(to, from, next)
})
router.afterEach((to, from, next) => {
  return CoreRouter.afterEach(to, from, next)
})
router.beforeEach((to, from, next) => {
  return CoreRouter.beforeEach(to, from, next)
})

export default router
