<template>
  <div class="flex-1 flex-shrink-0 flex flex-col gap-5 h-full">
    <div class="flex-shrink-0">
      <slot name="header" />
    </div>
    <div
      class="rounded-3xl flex flex-col gap-5 flex-1 overflow-hidden h-full"
      :class="{
        'px-5 py-2': !minimumPadding,
        'p-3': minimumPadding,
        'bg-panel': view !== 'messages',
        'bg-gray-200': view === 'messages',
      }"
    >
      <slot name="main" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PanelMeetingPods',
  props: {
    view: {
      type: String,
      default: '',
    },
    minimumPadding: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
